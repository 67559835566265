import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Text, TextInput } from '@mezzoforte/forge';
import { useForm } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';

export interface SavedSearchEmailModalValues {
  email: string;
}

interface SavedSearchEmailModalProps {
  term: string;
  isOpen: boolean;
  onSubmit: (values: SavedSearchEmailModalValues) => void;
  onClose: () => void;
}

export function SavedSearchEmailModal({ term, isOpen, onSubmit, onClose }: SavedSearchEmailModalProps) {
  const { handleSubmit, register } = useForm<SavedSearchEmailModalValues>();

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} size={{ base: 'sm', sm: 'lg' }}>
      <ModalHeader>Lisää hakuvahti</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Stack>
            <Text>
              Saat sähköpostin, kun hakua <strong>{term}</strong> vastaavia uusia kohteita tulee huudettavaksi. Voit
              poistaa hakuvahdin koska tahansa sähköpostin lopussa olevasta linkistä.
            </Text>
            <TextInput label="Sähköpostiosoite" type="email" {...register('email', { required: true })} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Peruuta</Button>
          <Button type="submit" variant="highlight">
            Lisää hakuvahti
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
