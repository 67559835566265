'use client';

import { Divider, Flex, Pagination, Tab, TabList, Tabs, Text } from '@mezzoforte/forge';
import { EntryListFilter } from '@/components/ListPage/EntryListFilter';
import { EntryList } from '@/components/EntryList/EntryList';

interface EntryListFilterAndResultsProps {
  categoryId?: number;
}

/**
 * EntryListFilterAndResults can't be rendered server-side because searchParams are not available,
 * so this skeleton is rendered instead. This should match the layout in EntryListFilterAndResults
 * to avoid CLS issues.
 */
export function EntryListFilterAndResultsSkeleton({ categoryId }: EntryListFilterAndResultsProps) {
  return (
    <>
      <EntryListFilter categoryId={categoryId} onSubmit={() => {}} />

      <Divider my={5} />

      <Flex
        mb={5}
        direction={{ base: 'column', md: 'row-reverse' }}
        align={{ base: 'start', md: 'center' }}
        justify="space-between"
        gap={3}
      >
        <Tabs
          sx={{
            '> div': {
              borderBottom: 'none',
            },
          }}
        >
          <TabList>
            <Tab>Päättyvät</Tab>
            <Tab>Kiinnostavimmat</Tab>
            <Tab>Uusimmat</Tab>
          </TabList>
        </Tabs>
        <Text>Ladataan...</Text>
      </Flex>

      <Flex>
        <EntryList dummyEntryCount={40} analytics={{ listId: '', listName: '' }} />
      </Flex>

      <Flex my={5} align="center" justify="center">
        <Pagination pageCount={100} />
      </Flex>
    </>
  );
}
