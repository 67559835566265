import { Button, Link, useToast } from '@mezzoforte/forge';
import { useCallback, useState } from 'react';
import { SavedSearchEmailModal, SavedSearchEmailModalValues } from '@/components/ListPage/SavedSearchEmailModal';
import { useSession } from '@/hooks/useSession';
import { useSavedSearch } from '@/hooks/useSavedSearch';
import { EntryListQuery } from '@/hooks/useEntryList';
import { gtmService } from '@/util/gtm-service';
import RouterLink from '@/components/Link/Link';

interface AddSavedSearchProps {
  query: EntryListQuery;
}

export function AddSavedSearch({ query }: AddSavedSearchProps) {
  const { currentUser } = useSession();
  const { addSavedSearch, removeSavedSearch } = useSavedSearch();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [savedSearchUuid, setSavedSearchUuid] = useState<string>();
  const { playToast } = useToast();

  const addSavedSearchWithToast = useCallback(
    (email?: string) => {
      addSavedSearch.mutate(
        { query, email },
        {
          onSuccess: (data) => {
            setSavedSearchUuid(data.uuid);
            playToast(
              'Hakuvahti lisättiin',
              email !== undefined ? (
                `Tilasit hakuvahdin osoitteeseen ${email}`
              ) : (
                <Link as={RouterLink} href="/oma-sivu/hakuvahdit" isExternal>
                  Katso kaikki hakuvahtisi
                </Link>
              ),
              { variant: 'success' }
            );
            gtmService.event('saved_search:add', { query });
          },
          onError: (error) => {
            if (error.response?.status === 429) {
              playToast('Virhe', 'Olet jo luonut maksimimäärän hakuvahteja.', { variant: 'danger' });
            } else {
              playToast('Virhe', 'Hakuvahdin tallentaminen epäonnistui.', { variant: 'danger' });
            }
          },
        }
      );
    },
    [addSavedSearch, playToast, query]
  );

  const onAddClick = useCallback(() => {
    if (!currentUser.data) {
      return;
    }

    if (!currentUser.data.isAuthenticated) {
      setIsEmailModalOpen(true);
      return;
    }

    addSavedSearchWithToast();
  }, [currentUser, addSavedSearchWithToast]);

  const onRemoveClick = useCallback(() => {
    if (savedSearchUuid === undefined) {
      return;
    }

    removeSavedSearch.mutate(
      { uuid: savedSearchUuid },
      {
        onSuccess: () => {
          setSavedSearchUuid(undefined);
        },
      }
    );
  }, [savedSearchUuid, removeSavedSearch]);

  const onEmailModalSubmit = useCallback(
    (values: SavedSearchEmailModalValues) => {
      addSavedSearchWithToast(values.email);
      setIsEmailModalOpen(false);
    },
    [addSavedSearchWithToast]
  );

  const onEmailModalClose = useCallback(() => {
    setIsEmailModalOpen(false);
  }, []);

  return (
    <>
      {savedSearchUuid === undefined ? (
        <Button variant="highlight" onClick={onAddClick} isLoading={addSavedSearch.status === 'pending'}>
          Lisää hakuvahti
        </Button>
      ) : (
        <Button variant="danger-primary" onClick={onRemoveClick} isLoading={removeSavedSearch.status === 'pending'}>
          Poista hakuvahti
        </Button>
      )}

      <SavedSearchEmailModal
        term={query.filter.searchTerm ?? ''}
        isOpen={isEmailModalOpen}
        onSubmit={onEmailModalSubmit}
        onClose={onEmailModalClose}
      />
    </>
  );
}
